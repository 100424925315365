import humps from 'humps';
import { compose, isNil, prop, sortBy, toLower, isEmpty } from 'ramda';

import { DEAL_FILTER, getDealFilterNames, LOAN_HASH, LOAN_ROUTES } from 'enums';

import { appRoutes } from 'router/routes';

import { createURL } from 'utils/url';
import { getLoansStatusesList, getLoanStatusLabel } from 'utils/loanStatusHelpers';
import { getOrganizationTypes } from 'utils/getOrganizationsType';

export const createNewDealLink = id => {
  const path = createURL({
    path: appRoutes.loanCreatePath(),
    searchParams: { successorBorrower: id },
  });
  return { name: 'Create Deal', to: path, icon: 'plusCircle' };
};

const createLoanLinkName = (status, statusRoute, data, isLoading) =>
  `${status} ${
    !isLoading && data?.[statusRoute]?.length ? `(${data?.[statusRoute]?.length})` : '(0)'
  }`;

const isEmptyList = (data, statusRoute) => !data[statusRoute]?.length;

export const createLoanNestedLink = to => (status, data, isLoading) => {
  const camelizedStatus = humps.camelize(status);
  const name = createLoanLinkName(
    getLoanStatusLabel(status),
    LOAN_ROUTES[camelizedStatus],
    data,
    isLoading,
  );
  const disabled = isEmptyList(data, LOAN_ROUTES[camelizedStatus]);
  return { name, to, disabled };
};

export const borrowerLoanNestedLinks = (id, data, loading) =>
  getLoansStatusesList().map(status => {
    const camelizedStatus = humps.camelize(status);
    const path = appRoutes.successorBorrowerLoansStatusPath(id, LOAN_ROUTES[camelizedStatus]);
    return createLoanNestedLink(path)(status, data, loading);
  });

const createLoansLinkParams = (statusInSnakeCase, count, pathname) => {
  const name = `${getLoanStatusLabel(statusInSnakeCase)} (${count ?? '0'})`;
  const disabled = isNil(count) || count === 0;
  return { name, to: pathname, disabled, statusQueryParam: statusInSnakeCase };
};

export const getCount = (loansCountsByStatus, loanStatus) => {
  if (isEmpty(loansCountsByStatus)) return 0;
  return loansCountsByStatus.statuses.find(item => item.status === loanStatus).amount;
};

export const createLoansNavLinks = loansCountsByStatus => {
  if (isNil(loansCountsByStatus)) return [];
  const pathname = appRoutes.loansPath();
  const totalLoansCount = loansCountsByStatus.total ?? 0;
  return [
    {
      name: `All (${totalLoansCount})`,
      to: appRoutes.loansPath(),
      disabled: isNil(totalLoansCount) || totalLoansCount === 0,
    },
    ...getLoansStatusesList().map(statusName =>
      createLoansLinkParams(statusName, getCount(loansCountsByStatus, statusName), pathname),
    ),
  ];
};

export const createSuccessorBorrowerLoansNavLinks = (successorBorrowerId, loansCountsByStatus) => {
  if (isNil(loansCountsByStatus)) return [];
  const pathname = appRoutes.successorBorrowerLoansPath(successorBorrowerId);
  return [
    {
      name: 'Loans',
      to: pathname,
    },
    ...getLoansStatusesList().map(statusName =>
      createLoansLinkParams(statusName, getCount(loansCountsByStatus, statusName), pathname),
    ),
  ];
};

export const organizationsNestedLinks = options => {
  const links = Object.keys(getOrganizationTypes(options)).map(key => ({
    name: getOrganizationTypes(options)[key],
    to: `${appRoutes.partiesOrganizationsPath()}#${humps.decamelize(key, { separator: '-' })}`,
  }));

  return sortBy(compose(toLower, prop('name')))(links);
};

export const dealsSideLinks = (listByFilters, loading) => [
  ...Object.keys(DEAL_FILTER).map(filter => {
    const filterData = listByFilters[filter];
    const filterDataLength = filterData?.length;
    const name = `${getDealFilterNames()[filter]} ${
      !loading && filterDataLength ? `(${filterDataLength})` : '(0)'
    }`;
    const pathname = appRoutes.dealsPath();
    const hash = `#${filter}`;
    const disabled = !filterDataLength;
    return {
      name,
      to: `${pathname}${hash}`,
      disabled,
    };
  }),
];

export const liveFormsLinks = () => [
  {
    name: 'Annual Mortgage Interest Accrual Report',
    to: appRoutes.annualMortgageInterestAccrualReportPath(),
  },
  { name: 'Buy Up Pending', to: appRoutes.buyUpPendingPath() },
  { name: 'Side Letter Pending', to: appRoutes.sideLetterPendingPath() },
  { name: 'Certificate of Authority', to: appRoutes.liveFormsCOAPath() },
  {
    name: 'Data Validation',
    to: appRoutes.liveFormsDataValidationReportPath(),
    activeOnPaths: [`${appRoutes.liveFormsDataValidationReportPath()}/*`],
  },
  { name: 'Deals Assumed: Historical Data', to: appRoutes.dealsSummaryOfDealsPath() },
  { name: 'Deals Assumed Sortable', to: appRoutes.assumedDealsPath() },
  {
    name: 'Freddie Float Payment',
    to: appRoutes.freddieFloatPaymentPath(),
  },
  { name: 'Missing Reconciliation Inputs', to: appRoutes.missingReconciliationInputsPath() },
  { name: 'OID Balance Report', to: appRoutes.liveFormsOIDBalanceReportPath() },
  { name: 'Outstanding Accountant Report', to: appRoutes.outstandingAccountantPath() },
  { name: 'Outstanding Closing Binders', to: appRoutes.outstandingClosingBindersPath() },
  {
    name: 'Reconciliation Discrepancies',
    to: appRoutes.liveFormsReconciliationDiscrepanciesPath(),
  },
];

export const loanDetailLinks = id => {
  if (isNil(id)) return [];
  return [
    {
      name: 'Information',
      to: appRoutes.loanPath(id),
      nestedLinks: [
        { name: 'Loan Details', to: appRoutes.loanDetailsPath(id) },
        { name: 'Parties', to: appRoutes.loanPartiesPath(id) },
        { name: 'Deal Checklist', to: appRoutes.loanDealChecklistPath(id) },
        { name: 'Note Issues', to: appRoutes.loanNoteIssuesPath(id) },
        { name: 'Post Closing', to: appRoutes.loanPostClosingPath(id) },
        { name: 'Sharing', to: appRoutes.loanSharingPath(id) },
        { name: 'Monetization', to: appRoutes.loanMonetizationPath(id) },
        { name: 'Hedging', to: appRoutes.loanHedgingPath(id) },
      ],
    },
    { name: 'Escrow Account', to: appRoutes.loanEscrowPath(id) },
    { name: 'Detail of Government Securities', to: appRoutes.loanGovernmentSecuritiesPath(id) },
    { name: 'Payments on the Defeased Loan', to: appRoutes.loanDefeasedPaymentsPath(id) },
    { name: 'Market Discount', to: appRoutes.loanMarketDiscountPath(id) },
    { name: 'Reconciliation Inputs', to: appRoutes.loanReconciliationInputsPath(id) },
  ];
};

export const createLoanCRUDLinks = (isEdit, loanId) => {
  if (isEdit && isNil(loanId)) {
    return [];
  }
  return {
    links: [
      { name: 'Loan Details', to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.details) },
      { name: 'Parties', to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.parties) },
      {
        name: 'Deal Checklist',
        to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.dealChecklist),
      },
      { name: 'Note Issues', to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.noteIssues) },
      {
        name: 'Post Closing',
        to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.postClosing),
      },
      {
        name: 'Sharing',
        to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.sharing),
      },
      {
        name: 'Monetization',
        to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.monetization),
      },
      {
        name: 'Hedging',
        to: appRoutes.loanCRUDHashPath(isEdit, loanId, LOAN_HASH.hedging),
      },
    ],
  };
};

export const organizationDetailLinks = id => {
  if (isNil(id)) {
    return [];
  }
  return (
    id && [
      {
        name: 'Information',
        to: { pathname: appRoutes.partiesOrganizationPath(id), hash: '#information' },
      },
      { name: 'People', to: { pathname: appRoutes.partiesOrganizationPath(id), hash: '#people' } },
    ]
  );
};

export const partiesLinks = options => [
  {
    name: 'Organizations',
    to: appRoutes.partiesOrganizationsPath(),
    nestedLinks: organizationsNestedLinks(options),
  },
  { name: 'People', to: appRoutes.partiesPeoplePath() },
];

export const getStaffLinks = () => ({
  title: 'Staff',
  links: [
    {
      name: 'Administrative Agents',
      to: appRoutes.staffAdministrativeAgentsPath(),
      activeOnPaths: [appRoutes.staffAdministrativeAgentPath(':id')],
    },
    {
      name: 'Members',
      to: appRoutes.staffMembersPath(),
      activeOnPaths: [appRoutes.staffMemberPath(':id')],
    },
    {
      name: 'Managers',
      to: appRoutes.staffManagersPath(),
      activeOnPaths: [appRoutes.staffManagerPath(':id')],
    },
  ],
});

export const createSuccessorBorrowerDetailsLink = id => {
  if (isNil(id)) {
    return [];
  }
  return {
    name: 'Information',
    to: appRoutes.successorBorrowerPath(id),
    nestedLinks: [
      { name: 'Successor Borrower Details', to: appRoutes.successorBorrowerDetailsPath(id) },
      { name: 'CMBS Pool', to: appRoutes.successorBorrowerPoolPath(id) },
      { name: 'Snapshots', to: appRoutes.successorBorrowerSnapshotsPath(id) },
    ],
  };
};

export const getHomeLinks = () => ({
  title: 'Home',
  links: [
    {
      name: 'Upcoming Property Par Repays',
      to: appRoutes.homeUpcomingParRepaysPath(),
    },
    {
      name: 'Upcoming Maturities',
      to: appRoutes.homeUpcomingMaturitiesPath(),
    },
    {
      name: 'Recently Closed Deals',
      to: appRoutes.homeRecentlyClosedDealsPath(),
    },
    {
      name: 'Overview',
      to: appRoutes.homeOverviewPath(),
    },
  ],
});

export const accountSettingsLinks = () => [
  {
    name: 'Password',
    to: appRoutes.changePassword(),
  },
];

export const personEditLinks = id => {
  if (isNil(id)) {
    return [];
  }
  return [
    {
      name: 'Person Details',
      to: appRoutes.personEditPath(id),
    },
  ];
};

export const memberCRUDLinks = id => {
  if (isNil(id)) {
    return [];
  }
  return [
    {
      name: 'Member Details',
      to: id ? appRoutes.memberEditPath(id) : appRoutes.administrativeAgentCreatePath(),
    },
  ];
};

export const administrativeAgentCRUDLinks = id => {
  if (isNil(id)) {
    return [];
  }
  return [
    {
      name: 'Administrative Agent Details',
      to: id
        ? appRoutes.administrativeAgentEditPath(id)
        : appRoutes.administrativeAgentCreatePath(),
    },
  ];
};

export const managerCRUDLinks = id => {
  if (isNil(id)) {
    return [];
  }
  return [
    {
      name: 'Manager Details',
      to: id ? appRoutes.managerEditPath(id) : appRoutes.managerCreatePath(),
    },
  ];
};
