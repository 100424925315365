import { createSlice } from '@reduxjs/toolkit';
import SideLetterPendingRepository from 'repositories/SideLetterPendingRepository';

import { createRestSlice } from 'utils/RestSlice';

const sideLetterPendingsRestSlice = createRestSlice({
  resource: 'sideLetterPending',
  repository: SideLetterPendingRepository,
  slices: ['loadSideLetterPendings', 'updateSideLetterPending'],
});

const initialState = {
  ...sideLetterPendingsRestSlice.initialState,
};

const sideLetterPendingsSlice = createSlice({
  name: 'sideLetterPendings',
  initialState,
  reducers: {
    ...sideLetterPendingsRestSlice.reducers,
  },
});

export default sideLetterPendingsSlice.reducer;
export const sideLetterPendingsRestHooks = sideLetterPendingsRestSlice.hooks(
  sideLetterPendingsSlice,
  'SideLetterPendingsSlice',
);
