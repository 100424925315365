import PropTypes from 'prop-types';

import { FORM_FIELD_TYPE } from 'enums';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { formatCurrency, formatPercent } from 'utils/numbers';

export const sideLetterPendingColumnConfig = {
  id: {
    displayedName: 'PID',
    isLink: true,
  },
  filingRef: {
    displayedName: 'Ref',
  },
  dealName: {
    displayedName: 'Deal Name',
  },
  assumedDate: {
    displayedName: 'Assumed Date',
  },
  sideLetterBorrowerPrincipal: {
    displayedName: 'Borrower Principal',
  },
  parRepayType: {
    displayedName: 'Par Repay Type',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  parRepayDate: {
    displayedName: 'Aggressive Par Repay Date',
  },
  parRepayConservativeDate: {
    displayedName: 'Conservative Par Repay Date',
  },
  originalBorrowerPercentageSplit: {
    displayedName: 'Original Borrower Percentage Split',
  },
  successorBorrowerPercentageSplit: {
    displayedName: 'Successor Borrower Percentage Split',
  },
  totalProceeds: {
    displayedName: 'Total Proceeds',
  },
  sharePayment: {
    displayedName: 'Share Payment',
  },
  sideLetterTerminationDate: {
    displayedName: 'Termination Date',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.date,
    fieldKey: 'sideLetterTerminationDate',
    align: 'right',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    dealName: PropTypes.string,
    assumedDate: PropTypes.date,
    sideLetterBorrowerPrincipal: PropTypes.string,
    parRepayType: PropTypes.string,
    maturityDate: PropTypes.date,
    parRepayDate: PropTypes.date,
    parRepayConservativeDate: PropTypes.date,
    originalBorrowerPercentageSplit: PropTypes.string,
    successorBorrowerPercentageSplit: PropTypes.string,
    totalProceeds: PropTypes.string,
    sharePayment: PropTypes.string,
    sideLetterTerminationDate: PropTypes.date,
  },
  {
    tableData(list) {
      if (!list) return [];
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
          assumedDate: formatDate(rowData.assumedDate),
          maturityDate: formatDate(rowData.maturityDate),
          parRepayDate: formatDate(rowData.parRepayDate),
          parRepayConservativeDate: formatDate(rowData.parRepayConservativeDate),
          originalBorrowerPercentageSplit: formatPercent(rowData.originalBorrowerPercentageSplit),
          successorBorrowerPercentageSplit: formatPercent(rowData.successorBorrowerPercentageSplit),
          totalProceeds: formatCurrency(rowData.totalProceeds),
          sharePayment: formatCurrency(rowData.sharePayment),
          sideLetterTerminationDate: formatDate(rowData.sideLetterTerminationDate),
        },
      }));
    },
    tableKeys() {
      return Object.keys(sideLetterPendingColumnConfig);
    },
    tableHeader() {
      return Object.values(sideLetterPendingColumnConfig).map(item => item.displayedName);
    },
  },
);
