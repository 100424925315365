import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import Loader from 'components/Loader';

import { PaginationAndOrderingProvider } from 'contexts/PaginationAndOrderingContext';
import { ScrollingProvider } from 'contexts/ScrollingContext';

import { NAV_TYPES } from 'enums';

import { useUsers } from 'hooks/api/useUsers';
import useSideNavContent from 'hooks/useSideNavContent';

import { AuthLayout } from 'layouts/AuthLayout';
import RootLayout from 'layouts/RootLayout';

import Login from 'pages/Login';
import HomeRecentlyClosedDeals from 'pages/HomeRecentlyClosedDeals';
import HomeUpcomingParRepays from 'pages/HomeUpcomingParRepays';
import HomeUpcomingMaturities from 'pages/HomeUpcomingMaturities';
import SuccessorBorrowers from 'pages/SuccessorBorrowers';
import SuccessorBorrower from 'pages/SuccessorBorrower';
import SuccessorBorrowerEdit from 'pages/SuccessorBorrowerEdit';
import SuccessorBorrowerAdd from 'pages/SuccessorBorrowerAdd';
import SuccessorBorrowerLoans from 'pages/SuccessorBorrowerLoans';
import Loans from 'pages/Loans';
import Pools from 'pages/Pools';
import LoanEscrowAccount from 'pages/LoanEscrowAccount';
import LoanMarketDiscount from 'pages/LoanMarketDiscount';
import LoanGovernmentSecurities from 'pages/LoanGovernmentSecurities';
import LoanDefeasedPayments from 'pages/LoanDefeasedPayments';
import LoanReconciliationInputs from 'pages/LoanReconciliationInputs';
import AdministrativeAgents from 'pages/AdministrativeAgents';
import AdministrativeAgent from 'pages/AdministrativeAgent';
import AdministrativeAgentAdd from 'pages/AdministrativeAgentAdd';
import AdministrativeAgentEdit from 'pages/AdministrativeAgentEdit';
import Members from 'pages/Members';
import Member from 'pages/Member';
import MemberAdd from 'pages/MemberAdd';
import MemberEdit from 'pages/MemberEdit';
import Manager from 'pages/Manager';
import Managers from 'pages/Managers';
import ManagerAdd from 'pages/ManagerAdd';
import ManagerEdit from 'pages/ManagerEdit';
import MissingReconciliationInputs from 'pages/MissingReconciliationInputs';
import Loan from 'pages/Loan';
import LoanCreate from 'pages/LoanCreate';
import LoanEdit from 'pages/LoanEdit';
import Pool from 'pages/Pool';
import PoolAdd from 'pages/PoolAdd';
import PoolEdit from 'pages/PoolEdit';
import People from 'pages/People';
import PersonEdit from 'pages/PersonEdit';
import PersonAdd from 'pages/PersonAdd';
import Organization from 'pages/Organization';
import Organizations from 'pages/Organizations';
import OrganizationEdit from 'pages/OrganizationEdit';
import OrganizationAdd from 'pages/OrganizationAdd';
import OIDBalanceReport from 'pages/OIDBalanceReport';
import OIDBalanceLoanDetails from 'pages/OIDBalanceLoanDetails';
import OIDBalanceSuccessorBorrowerDetails from 'pages/OIDBalanceSuccessorBorrowerDetails';
import OIDBalanceMemberDetails from 'pages/OIDBalanceMemberDetails';
import Deals from 'pages/Deals';
import AssumedDeals from 'pages/AssumedDeals';
import MortgageInterestAccrualReport from 'pages/MortgageInterestAccrualReport';
import RemindersCOA from 'pages/RemindersCOA';
import ReconciliationDiscrepancies from 'pages/ReconciliationDiscrepancies';
import OutstandingClosingBinders from 'pages/OutstandingClosingBinders';
import BuyUpPending from 'pages/BuyUpPending';
import SideLetterPending from 'pages/SideLetterPending';
import HomeOverview from 'pages/HomeOverview';
import Tools from 'pages/Tools';
import OutstandingAccountant from 'pages/OutstandingAccountant';
import SummaryOfDeals from 'pages/SummaryOfDeals';
import PageNotFound from 'pages/PageNotFound';
import DataValidationReport from 'pages/DataValidationReport';
import FundingNotes from 'pages/FundingNotes';
import PasswordRecovery from 'pages/PasswordRecovery';
import PasswordResetForm from 'pages/PasswordResetForm';
import FreddieFloatPayment from 'pages/FreddieFloatPayment';
import ChangePassword from 'pages/AccountSettings/ChangePassword';

import createAxiosResponseInterceptor from 'utils/createAxiosResponseInterceptor';
import { isPasswordRecoveryPaths } from 'utils/paths';

import { appRoutes } from './routes';

export const NAV_CONFIG = {
  [NAV_TYPES.homepage]: [
    appRoutes?.homeRecentlyClosedDealsPath(),
    appRoutes?.homeUpcomingParRepaysPath(),
    appRoutes?.homeUpcomingMaturitiesPath(),
    appRoutes?.homeOverviewPath(),
  ],
  [NAV_TYPES.successorBorrowers]: [appRoutes?.successorBorrowersPath()],
  [NAV_TYPES.successorBorrowerCRUD]: [
    appRoutes?.successorBorrowerEditPath(':id'),
    appRoutes?.successorBorrowerCreatePath(),
  ],
  [NAV_TYPES.poolsCRUD]: [appRoutes?.poolEditPath(':id'), appRoutes?.poolCreatePath()],
  [NAV_TYPES.loans]: [appRoutes?.loansPath(), appRoutes?.loansInStatusPath(':status')],
  [NAV_TYPES.loanCreate]: [appRoutes?.loanCreatePath()],
  [NAV_TYPES.loanEdit]: [appRoutes?.loanEditPath(':id')],
  [NAV_TYPES.loanDetails]: [
    appRoutes?.loanPath(':id'),
    appRoutes?.loanDetailsPath(':id'),
    appRoutes?.loanPartiesPath(':id'),
    appRoutes?.loanDealChecklistPath(':id'),
    appRoutes?.loanNoteIssuesPath(':id'),
    appRoutes?.loanPostClosingPath(':id'),
    appRoutes?.loanSharingPath(':id'),
    appRoutes?.loanMonetizationPath(':id'),
    appRoutes?.loanHedgingPath(':id'),
    appRoutes?.loanEscrowPath(':id'),
    appRoutes?.loanGovernmentSecuritiesPath(':id'),
    appRoutes?.loanDefeasedPaymentsPath(':id'),
    appRoutes?.loanMarketDiscountPath(':id'),
    appRoutes?.loanReconciliationInputsPath(':id'),
  ],
  [NAV_TYPES.deals]: [appRoutes?.dealsPath()],
  [NAV_TYPES.parties]: [
    appRoutes?.partiesPath(),
    appRoutes?.partiesOrganizationsPath(),
    appRoutes?.partiesPeoplePath(),
  ],
  [NAV_TYPES.personCRUD]: [
    appRoutes?.partiesPersonEditPath(':id'),
    appRoutes?.partiesPersonCreatePath(),
  ],
  [NAV_TYPES.organizationCRUD]: [
    appRoutes?.partiesOrganizationEditPath(':id'),
    appRoutes?.partiesOrganizationCreatePath(),
  ],
  [NAV_TYPES.organizationDetail]: [appRoutes?.partiesOrganizationPath(':id')],
  [NAV_TYPES.liveForms]: [
    appRoutes?.liveFormsPath(),
    appRoutes?.assumedDealsPath(),
    appRoutes?.liveFormsReconciliationDiscrepanciesPath(),
    appRoutes?.liveFormsOIDBalanceReportPath(),
    appRoutes?.missingReconciliationInputsPath(),
    appRoutes?.outstandingAccountantPath(),
    appRoutes?.dealsSummaryOfDealsPath(),
    appRoutes?.outstandingClosingBindersPath(),
    appRoutes?.buyUpPendingPath(),
    appRoutes?.sideLetterPendingPath(),
    appRoutes?.annualMortgageInterestAccrualReportPath(),
    appRoutes?.liveFormsDataValidationReportPath(),
    appRoutes?.liveFormsCOAPath(),
    appRoutes?.freddieFloatPaymentPath(),
  ],
  [NAV_TYPES.staff]: [
    appRoutes?.staffPath(),
    appRoutes?.staffAdministrativeAgentsPath(),
    appRoutes?.staffMembersPath(),
    appRoutes?.staffManagersPath(),
    appRoutes.staffAdministrativeAgentPath(':id'),
    appRoutes.staffMemberPath(':id'),
    appRoutes.staffManagerPath(':id'),
  ],
  [NAV_TYPES.accountSettings]: [appRoutes?.changePassword()],
};

export const history = createBrowserHistory();
createAxiosResponseInterceptor(history);

const RootRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => (
      <RootLayout>
        <Component {...routeProps} />
      </RootLayout>
    )}
  />
);
RootRoute.propTypes = {
  component: PropTypes.func,
};

const SideBarRoute = ({ component: Component, ...rest }) => {
  const sideNavContent = useSideNavContent();
  return (
    <Route
      {...rest}
      render={routeProps => (
        <RootLayout sideNavContent={sideNavContent}>
          <Component {...routeProps} />
        </RootLayout>
      )}
    />
  );
};
SideBarRoute.propTypes = {
  component: PropTypes.func,
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => (
      <AuthLayout>
        <Component {...routeProps} />
      </AuthLayout>
    )}
  />
);
AuthRoute.propTypes = {
  component: PropTypes.func,
};

export const CustomRouter = () => {
  const { isCurrentUserLoading, loadCurrentUser, isCurrentUserFulfilled } = useUsers();

  const hasAuth = localStorage.getItem('dhc');

  useEffect(() => {
    if (!isCurrentUserFulfilled && !hasAuth && !isPasswordRecoveryPaths(history)) {
      loadCurrentUser();
    }
  }, []);

  if (isCurrentUserLoading) {
    return <Loader />;
  }

  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <PaginationAndOrderingProvider>
          <ScrollingProvider>
            <Switch>
              <AuthRoute exact path={appRoutes.authPath()} component={Login} />
              <AuthRoute
                exact
                path={appRoutes.passwordRecoveryPath()}
                component={PasswordRecovery}
              />
              <AuthRoute exact path={appRoutes.passwordResetPath()} component={PasswordResetForm} />

              <Route exact path={appRoutes.homePath()}>
                <Redirect to={{ pathname: appRoutes.homeUpcomingParRepaysPath() }} />
              </Route>
              <Route
                path={appRoutes.homeRecentlyClosedDealsPath()}
                component={HomeRecentlyClosedDeals}
              />
              <Route
                path={appRoutes.homeUpcomingParRepaysPath()}
                component={HomeUpcomingParRepays}
              />
              <Route
                path={appRoutes.homeUpcomingMaturitiesPath()}
                component={HomeUpcomingMaturities}
              />
              <Route path={appRoutes.homeOverviewPath()} component={HomeOverview} />
              <Route
                exact
                path={appRoutes.successorBorrowersPath()}
                component={SuccessorBorrowers}
              />
              <Route
                exact
                path={appRoutes.successorBorrowerCreatePath()}
                component={SuccessorBorrowerAdd}
              />
              <Route
                exact
                path={appRoutes.successorBorrowerPath(':id')}
                component={SuccessorBorrower}
              />
              <Route
                exact
                path={appRoutes.successorBorrowerEditPath(':id')}
                component={SuccessorBorrowerEdit}
              />
              <Route
                exact
                path={appRoutes.successorBorrowerLoansPath(':id')}
                component={SuccessorBorrowerLoans}
              />
              <Route
                exact
                path={appRoutes.successorBorrowerLoansStatusPath(':id', ':route')}
                component={SuccessorBorrowerLoans}
              />

              <Route exact path={appRoutes.poolsPath()} component={Pools} />
              <Route exact path={appRoutes.poolCreatePath()} component={PoolAdd} />
              <Route exact path={appRoutes.poolPath(':id')} component={Pool} />
              <Route exact path={appRoutes.poolEditPath(':id')} component={PoolEdit} />

              <Route exact path={appRoutes.loansPath()} component={Loans} />
              <SideBarRoute exact path={appRoutes.loansInStatusPath(':status')} component={Loans} />
              <Route exact path={appRoutes.loanCreatePath()} component={LoanCreate} />
              <Route exact path={appRoutes.loanPath(':id')} component={Loan} />
              <Route exact path={appRoutes.loanEditPath(':id')} component={LoanEdit} />
              <Route exact path={appRoutes.loanEscrowPath(':id')} component={LoanEscrowAccount} />
              <Route
                exact
                path={appRoutes.loanMarketDiscountPath(':id')}
                component={LoanMarketDiscount}
              />
              <Route
                exact
                path={appRoutes.loanGovernmentSecuritiesPath(':id')}
                component={LoanGovernmentSecurities}
              />
              <Route
                exact
                path={appRoutes.loanDefeasedPaymentsPath(':id')}
                component={LoanDefeasedPayments}
              />
              <Route
                exact
                path={appRoutes.loanReconciliationInputsPath(':id')}
                component={LoanReconciliationInputs}
              />

              <Route exact path={appRoutes.dealsPath()} component={Deals} />

              <SideBarRoute exact path={appRoutes.partiesPath()}>
                <Redirect to={{ pathname: appRoutes.partiesOrganizationsPath() }} />
              </SideBarRoute>
              <SideBarRoute
                exact
                path={appRoutes.partiesOrganizationsPath()}
                component={Organizations}
              />
              <SideBarRoute exact path={appRoutes.partiesPeoplePath()} component={People} />
              <RootRoute exact path={appRoutes.partiesPersonCreatePath()} component={PersonAdd} />
              <RootRoute
                exact
                path={appRoutes.partiesPersonEditPath(':id')}
                component={PersonEdit}
              />
              <RootRoute
                exact
                path={appRoutes.partiesOrganizationCreatePath()}
                component={OrganizationAdd}
              />
              <Route
                exact
                path={appRoutes.partiesOrganizationPath(':id')}
                component={Organization}
              />
              <RootRoute
                exact
                path={appRoutes.partiesOrganizationEditPath(':id')}
                component={OrganizationEdit}
              />

              <SideBarRoute exact path={appRoutes.liveFormsPath()}>
                <Redirect to={{ pathname: appRoutes.annualMortgageInterestAccrualReportPath() }} />
              </SideBarRoute>
              <SideBarRoute
                exact
                path={appRoutes.liveFormsReconciliationDiscrepanciesPath()}
                component={ReconciliationDiscrepancies}
              />
              <SideBarRoute
                exact
                path={appRoutes.liveFormsOIDBalanceReportPath()}
                component={OIDBalanceReport}
              />
              <SideBarRoute
                exact
                path={appRoutes.liveFormsDataValidationReportPath()}
                component={DataValidationReport}
              />
              <SideBarRoute exact path={appRoutes.liveFormsCOAPath()} component={RemindersCOA} />

              <SideBarRoute
                exact
                path={appRoutes.missingReconciliationInputsPath()}
                component={MissingReconciliationInputs}
              />

              <SideBarRoute
                exact
                path={appRoutes.annualMortgageInterestAccrualReportPath()}
                component={MortgageInterestAccrualReport}
              />
              <SideBarRoute exact path={appRoutes.assumedDealsPath()} component={AssumedDeals} />
              <SideBarRoute
                exact
                path={appRoutes.outstandingAccountantPath()}
                component={OutstandingAccountant}
              />
              <SideBarRoute
                exact
                path={appRoutes.dealsSummaryOfDealsPath()}
                component={SummaryOfDeals}
              />
              <SideBarRoute
                exact
                path={appRoutes.outstandingClosingBindersPath()}
                component={OutstandingClosingBinders}
              />
              <SideBarRoute exact path={appRoutes.buyUpPendingPath()} component={BuyUpPending} />
              <SideBarRoute
                exact
                path={appRoutes.sideLetterPendingPath()}
                component={SideLetterPending}
              />

              <SideBarRoute
                exact
                path={appRoutes.freddieFloatPaymentPath()}
                component={FreddieFloatPayment}
              />
              <Route
                exact
                path={appRoutes.OIDBalanceReportMembersPath(':id')}
                component={OIDBalanceMemberDetails}
              />
              <Route
                exact
                path={appRoutes.OIDBalanceReportLoanPath(':id')}
                component={OIDBalanceLoanDetails}
              />
              <Route
                exact
                path={appRoutes.OIDBalanceReportSuccessoBorrowerPath(':id')}
                component={OIDBalanceSuccessorBorrowerDetails}
              />

              <Route exact path={appRoutes.staffPath()}>
                <Redirect to={{ pathname: appRoutes.staffAdministrativeAgentsPath() }} />
              </Route>
              <Route
                exact
                path={appRoutes.staffAdministrativeAgentsPath()}
                component={AdministrativeAgents}
              />
              <RootRoute
                exact
                path={appRoutes.staffAdministrativeAgentCreatePath()}
                component={AdministrativeAgentAdd}
              />
              <RootRoute
                exact
                path={appRoutes.staffAdministrativeAgentEditPath(':id')}
                component={AdministrativeAgentEdit}
              />
              <Route
                exact
                path={appRoutes.staffAdministrativeAgentPath(':id')}
                component={AdministrativeAgent}
              />
              <RootRoute
                exact
                path={appRoutes.staffAdministrativeAgentEditPath(':id')}
                component={AdministrativeAgentEdit}
              />

              <Route exact path={appRoutes.staffMembersPath()} component={Members} />
              <RootRoute exact path={appRoutes.staffMemberCreatePath()} component={MemberAdd} />
              <RootRoute exact path={appRoutes.staffMemberEditPath(':id')} component={MemberEdit} />
              <Route exact path={appRoutes.staffMemberPath(':id')} component={Member} />
              <RootRoute exact path={appRoutes.staffMemberEditPath(':id')} component={MemberEdit} />
              <Route exact path={appRoutes.staffManagersPath()} component={Managers} />
              <RootRoute exact path={appRoutes.staffManagerCreatePath()} component={ManagerAdd} />
              <RootRoute
                exact
                path={appRoutes.staffManagerEditPath(':id')}
                component={ManagerEdit}
              />
              <Route exact path={appRoutes.staffManagerPath(':id')} component={Manager} />
              <RootRoute
                exact
                path={appRoutes.staffManagerEditPath(':id')}
                component={ManagerEdit}
              />
              <RootRoute exact path={appRoutes.toolsPath()} component={Tools} />

              <RootRoute exact path={appRoutes.fundingNotesPath()} component={FundingNotes} />

              <Redirect exact from="/" to={appRoutes.homeUpcomingParRepaysPath()} />
              <Redirect
                exact
                from={appRoutes.reportsPath()}
                to={appRoutes.homeUpcomingParRepaysPath()}
              />

              <SideBarRoute exact path={appRoutes.changePassword()} component={ChangePassword} />

              <Route path="*" component={PageNotFound} />
            </Switch>
          </ScrollingProvider>
        </PaginationAndOrderingProvider>
      </QueryParamProvider>
    </Router>
  );
};
