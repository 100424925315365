import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableBottomRow: {
      background: theme.palette.background.default,
    },
    tableBottomCell: {
      height: 48,
      padding: [16, 12],
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    buyUpPendingContainer: {
      marginLeft: 0,
    },
    buyUpPendingList: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    buyUpPendingItem: {
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },
    iconWrapper: {
      padding: [0, 8],
      display: 'flex',
    },
    buyUpPendingButton: {
      justifyContent: 'flex-start',
    },
  }),
  { name: 'BuyUpPending' },
);
