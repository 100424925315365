import SideLetterPendingPresenter from 'presenters/SideLetterPendingPresenter';

import { sideLetterPendingsRestHooks } from 'store/SideLetterPendingsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useSideLetterPending = () => {
  const { sideLetterPendings, loadSideLetterPendings, updateSideLetterPending } =
    sideLetterPendingsRestHooks.use();
  const sideLetterPendingTableData =
    SideLetterPendingPresenter.tableData(sideLetterPendings?.items) || [];
  const sideLetterPendingTableKeys = SideLetterPendingPresenter.tableKeys();

  return {
    sideLetterPendings,
    loadSideLetterPendings,
    updateSideLetterPending,
    sideLetterPendingTableData,
    sideLetterPendingTableKeys,
    isSideLetterPendingLoading: useFetchStatus(sideLetterPendings.loadingStatus).isPending,
  };
};
