import React, { useEffect } from 'react';

import SideLetterPendingRepository from 'repositories/SideLetterPendingRepository';
import { Button, Icon } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';
import SimpleDownloadReportPopup from 'components/SimpleDownloadReportPopup';

import { SIDE_LETTER_PENDING_POPUP } from 'enums';

import { useSideLetterPending } from 'hooks/api/useSideLetterPending';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { sideLetterPendingColumnConfig } from 'presenters/SideLetterPendingPresenter';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Side Letter Pending';

export const SideLetterPending = () => {
  const {
    loadSideLetterPendings,
    updateSideLetterPending,
    sideLetterPendingTableData,
    sideLetterPendingTableKeys,
    isSideLetterPendingLoading,
  } = useSideLetterPending();

  const classes = useStyles();

  const { displayErrorsInToast } = useErrors();

  const { elementRef, elementHeight } = useRefElementHeight();

  const [currentPopup, setCurrentPopup] = React.useState(null);

  const sideLetterPendingsItems = [
    {
      title: 'Side Letter Pending Report',
      clickHandler: () => {
        setCurrentPopup(SIDE_LETTER_PENDING_POPUP.side_letter_pending_report);
      },
    },
  ];

  const isCurrentPopup = popup => popup === currentPopup;

  const fetchData = async () => {
    try {
      await loadSideLetterPendings();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (id, fieldKey, fieldValue) => {
    const params = {
      [fieldKey]: fieldValue,
    };
    await updateSideLetterPending(id, params);
  };

  const tableBottomLine = <tr className={classes.tableBottomRow} />;

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState />
      <div className={classes.sideLetterPendingContainer}>
        <ul className={classes.sideLetterPendingList}>
          {sideLetterPendingsItems.map(item => (
            <li className={classes.sideLetterPendingItem} key={item.title}>
              {item.iconName && (
                <div className={classes.iconWrapper}>
                  <Icon name={item.iconName} />
                </div>
              )}
              <Button
                className={classes.sideLetterPendingButton}
                color="secondary"
                onClick={item.clickHandler}
              >
                {item.title}
              </Button>
            </li>
          ))}
        </ul>
      </div>
      {isCurrentPopup(SIDE_LETTER_PENDING_POPUP.side_letter_pending_report) && (
        <SimpleDownloadReportPopup
          callback={SideLetterPendingRepository.sideLetterPendingReport}
          setPopupState={setCurrentPopup}
          popupTitleText="Side Letter Pending Report"
        />
      )}
      <ListTable
        detailPath={appRoutes.loanPath}
        isLoading={isSideLetterPendingLoading}
        tableHeader={sideLetterPendingTableKeys}
        tableData={sideLetterPendingTableData}
        columnConfig={sideLetterPendingColumnConfig}
        onEdit={handleEdit}
        tableBottomContent={tableBottomLine}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
