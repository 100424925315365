import React, { useEffect, useState } from 'react';

import { Button, Icon } from '@material-ui/core';
import BuyUpPendingRepository from 'repositories/BuyUpPendingRepository';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';
import SimpleDownloadReportPopup from 'components/SimpleDownloadReportPopup';

import { BUY_UP_PENDING_POPUP } from 'enums';

import { useBuyUpPending } from 'hooks/api/useBuyUpPending';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { buyUpPendingColumnConfig } from 'presenters/BuyUpPendingPresenter';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Buy Up Pending';

export const BuyUpPending = () => {
  const {
    loadBuyUpPendings,
    updateBuyUpPending,
    buyUpPendingTableData,
    buyUpPendingTableKeys,
    isBuyUpPendingLoading,
    totalAmount,
    totalDifference,
  } = useBuyUpPending();

  const classes = useStyles();

  const { displayErrorsInToast } = useErrors();

  const { elementRef, elementHeight } = useRefElementHeight();

  const [currentPopup, setCurrentPopup] = useState(null);

  const buyUpPendingsItems = [
    {
      title: 'Buy Up Pending Report',
      clickHandler: () => {
        setCurrentPopup(BUY_UP_PENDING_POPUP.buy_up_pending_report);
      },
    },
  ];

  const isCurrentPopup = popupCode => popupCode === currentPopup;

  const fetchData = async () => {
    try {
      await loadBuyUpPendings();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (id, fieldKey, fieldValue) => {
    const params = {
      [fieldKey]: fieldValue,
    };
    await updateBuyUpPending(id, params);
  };

  const tableBottomLine = (
    <tr className={classes.tableBottomRow}>
      <td colSpan={7} className={classes.tableBottomCell}>
        Summary
      </td>
      <td className={classes.tableBottomCell}>{totalAmount}</td>
      <td className={classes.tableBottomCell}>{totalDifference}</td>
    </tr>
  );

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState />
      <div className={classes.buyUpPendingContainer}>
        <ul className={classes.buyUpPendingList}>
          {buyUpPendingsItems.map(item => (
            <li className={classes.buyUpPendingItem} key={item.title}>
              {item.iconName && (
                <div className={classes.iconWrapper}>
                  <Icon name={item.iconName} />
                </div>
              )}
              <Button
                className={classes.buyUpPendingButton}
                color="secondary"
                onClick={item.clickHandler}
              >
                {item.title}
              </Button>
            </li>
          ))}
        </ul>
      </div>
      {isCurrentPopup(BUY_UP_PENDING_POPUP.buy_up_pending_report) && (
        <SimpleDownloadReportPopup
          callback={BuyUpPendingRepository.buyUpPendingReport}
          setPopupState={setCurrentPopup}
          popupTitleText="Buy Up Pending Report"
        />
      )}
      <ListTable
        detailPath={appRoutes.loanPath}
        isLoading={isBuyUpPendingLoading}
        tableHeader={buyUpPendingTableKeys}
        tableData={buyUpPendingTableData}
        columnConfig={buyUpPendingColumnConfig}
        onEdit={handleEdit}
        tableBottomContent={tableBottomLine}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
